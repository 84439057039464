<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Liste des commandes</div>

              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search"
                />
                <router-link to="/addcommandes">
                <button
                    class="btn btn-info float-right"
                    id="additem"
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    Ajouter une commande
                  </button>
                </router-link>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">code</th>
                      <th scope="col">etat commande</th>
                      <th scope="col">date commande</th>
                      <th scope="col">client</th>
                      <th scope="col">Le damandeur</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(commande, index) in searchFunction"
                      :key="index"
                    >
                      <th>{{ commande.numero }}</th>
                      <td>
                        <span
                          class="badge rounded-pill bg-secondary"
                          v-if="commande.etat_commande == 'en attente'"
                          >Commande en attente</span
                        >

                        <span
                          class="badge rounded-pill bg-info"
                          v-if="commande.etat_commande == 'en preparation'"
                          >Commande en preparation</span
                        >

                        <span
                          class="badge rounded-pill bg-danger"
                          v-if="commande.etat_commande == 'refuse'"
                          >Commande refusé</span
                        >
                        <span
                            class="badge rounded-pill bg-success"
                          v-if="commande.etat_commande == 'valide_magasinier'"

                            >Commande validé</span
                          >
                          <span
                            class="badge rounded-pill bg-warning"
                          v-if="commande.etat_commande == 'attente_stock'"

                            >Commande en attente du stock</span
                          >
                      </td>

                      <td>{{ commande.date_commande | formatDate }}</td>
                      <td>{{ commande.client_id["name"] }}</td>
                      <td>{{ commande.user_id["name"] }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'showcommandes',
                            params: { id: commande._id },
                          }"
                          href="#"
                        >
                        <i 
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                        
                        >
                        </i>
                        </router-link>

                        <i
                          class="mdi mdi-check-circle icone text-success"
                          title="Valider la commande"
                          @click="valide(commande._id)"
                          v-if="commande.etat_commande == 'en attente'"
                        >
                        </i>
                        <i
                          class="mdi mdi-close-octagon icone text-danger"
                          title="Refuser la commande"
                          @click="refuse(commande._id)"
                          v-if="commande.etat_commande == 'en attente'"
                        ></i>
                        <!-- Button trigger modal -->

                        <!-- Modal -->
                        <!-- <div
                          class="modal fade"
                          :id="'test' + commande._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                  <span style="font-size: 13px"
                                    >Commande No =</span
                                  >
                                  <b>{{ commande.numero }}</b>
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 10px; margin-bottom: 20px"
                                >
                                  <h5>Les informations client :</h5>
                                </div>
                                <table class="table table-bordered">
                                  <tr>
                                    <td>Nom & Prénom</td>
                                    <th>{{ commande.client_id.name }}</th>
                                  </tr>
                                  <tr>
                                    <td>Categorie</td>
                                    <th>{{ commande.client_id.categorie }}</th>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <th>{{ commande.client_id.email }}</th>
                                  </tr>
                                  <tr>
                                    <td>Adresse</td>
                                    <th>{{ commande.client_id.adresse }}</th>
                                  </tr>
                                  <tr>
                                    <td>Telephone</td>
                                    <th>{{ commande.client_id.phone }}</th>
                                  </tr>
                                  <tr v-if="commande.client_id.specialite">
                                    <td>Specialite</td>
                                    <th>{{ commande.client_id.specialite }}</th>
                                  </tr>

                                  <tr v-if="commande.client_id.mode_exercice">
                                    <td>Mode_exercice</td>
                                    <th>
                                      {{ commande.client_id.mode_exercice }}
                                    </th>
                                  </tr>

                                  <tr v-if="commande.client_id.delegation">
                                    <td>Mode_exercice</td>
                                    <th>{{ commande.client_id.delegation }}</th>
                                  </tr>

                                  <tr v-if="commande.client_id.gouvernerat">
                                    <td>Gouvernerat</td>
                                    <th>
                                      {{ commande.client_id.gouvernerat }}
                                    </th>
                                  </tr>
                                </table>

                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les articles du commande</h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Reference</th>
                                      <th>Quantité demandé</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        produit, index
                                      ) in commande.produits"
                                      :key="index"
                                    >
                                      <td>
                                        {{ produit.produit_id.reference }}
                                      </td>
                                      <td>{{ produit.quantites }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              
                            </div>
                          </div>
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  data() {
    return {
      commandes: [],
      search: "",
    };
  },

  created() {
    this.getcommandes();
  },
  computed: {
    searchFunction() {
      return this.commandes.filter((item) => {
        return (
          item.numero.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    getcommandes() {
      HTTP.get("commandes/getcommandes")
        .then((response) => {
          this.commandes = response.data;
        })
        .then((err) => console.log(err));
    },
    valide(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez valider la commande",
        icon: "success",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("commandes/validecommande/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "commande en préparation",
            });

            this.getcommandes();
          });
        }
      });
    },

    refuse(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez refusé la commande",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, refuser!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("commandes/refusercommande/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "commande refusé",
            });

            this.getcommandes();
          });
        }
      });
    },
  },
};
</script>
